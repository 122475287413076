import { render, staticRenderFns } from "./VipProblemManager.vue?vue&type=template&id=6ef44d46&scoped=true"
import script from "./VipProblemManager.vue?vue&type=script&lang=js"
export * from "./VipProblemManager.vue?vue&type=script&lang=js"
import style0 from "./VipProblemManager.vue?vue&type=style&index=0&id=6ef44d46&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6ef44d46",
  null
  
)

export default component.exports