<template>
  <div class="container" v-loading.fullscreen.lock="showLoading">
    <!-- 第一行：CDN刷新和文件删除 -->
    <el-row :gutter="20" class="top-row">
      <!-- CDN刷新 -->
      <el-col :span="12">
        <el-card class="function-card">
          <div slot="header" class="card-header">
            <i class="el-icon-refresh-right"></i>
            <span>CDN刷新</span>
          </div>
          <div class="card-body">
            <el-input
                v-model="cdnFilePath"
                placeholder="请输入完整的文件路径（如：release/imageNew/Pmqm5e55.png）"
                clearable
                class="path-input"
            >
              <template slot="prepend">URL路径</template>
            </el-input>
            <el-button
                type="success"
                icon="el-icon-refresh"
                @click="refreshCDNFile"
                class="action-btn"
            >立即刷新
            </el-button>
          </div>
        </el-card>
      </el-col>

      <!-- 文件删除 -->
      <el-col :span="12">
        <el-card class="function-card">
          <div slot="header" class="card-header">
            <i class="el-icon-delete"></i>
            <span>文件删除</span>
          </div>
          <div class="card-body">
            <el-input
                v-model="deleteFilePath"
                placeholder="请输入要删除的完整文件路径（如：release/imageNew/Pmqm5e55.png）"
                clearable
                class="path-input"
            >
              <template slot="prepend">文件路径</template>
            </el-input>
            <el-button
                type="danger"
                icon="el-icon-delete"
                @click="delFile"
                class="action-btn"
            >确认删除
            </el-button>
          </div>
        </el-card>
      </el-col>
    </el-row>

    <!-- 第二行：文件上传 -->
    <el-row :gutter="20" class="top-row">
      <el-col :span="24">
        <el-card class="upload-card">
          <div slot="header" class="card-header">
            <i class="el-icon-upload"></i>
            <span>上传本地文件到oss</span>
            <div class="upload-settings">
              <el-switch
                  v-model="uploadSupportPath"
                  active-text="自定义上传路径和文件名,默认在release/other文件夹下"
                  active-color="#13ce66"
              ></el-switch>
            </div>
          </div>

          <div class="upload-body">
            <!-- 自定义路径输入 -->
            <div v-if="uploadSupportPath" class="custom-path">
              <el-input
                  v-model="uploadFilePath"
                  placeholder="请输入自定义路径（示例：release/other/text.png）"
                  clearable
                  class="path-input"
              >
                <template slot="prepend">文件路径</template>
              </el-input>
            </div>

            <!-- 上传区域 -->
            <el-upload
                action=""
                class="uploader"
                drag
                :auto-upload="false"
                :on-change="handleFileChange"
                :on-remove="handleFileRemove"
                :limit="1"
                :class="{ 'with-path': uploadSupportPath }"
            >
              <div class="upload-content">
                <i class="el-icon-upload"></i>
                <div class="upload-text">
                  <p class="main-text">将文件拖到此处，或</p>
                  <p class="sub-text">点击上传（文件不要太大）</p>
                </div>
              </div>
            </el-upload>

            <el-button
                type="primary"
                icon="el-icon-upload2"
                @click="uploadFile"
                class="upload-btn"
                :disabled="!currentFile"
            >开始上传
            </el-button>
          </div>
        </el-card>
      </el-col>
    </el-row>

    <!-- 第三行：文件上传 -->
    <el-row :gutter="20" class="top-row">
      <el-col :span="24">
        <el-card class="upload-card">
          <div style="margin-bottom: 8px">刷新image_data_cdn配置的图片列表数据{"tietu":"release/other/image_list_tietu.txt","template":"release/other/image_list_template.txt"}</div>
          <el-button
              type="success"
              icon="el-icon-refresh"
              @click="refreshImageListCDNFile"
              class="action-btn"
          >立即刷新暴走P图所有图片列表CDN数据
          </el-button>
        </el-card>
      </el-col>
    </el-row>
    <!-- 第三行：文件上传 -->
    <el-row :gutter="20" class="top-row">
      <el-col :span="24" >
        <el-card class="upload-card">
          <div style="margin-bottom: 8px">刷新image_data_cdn配置的图片列表数据{"cdn":true,"tietu":"release/other/ml_image_list_tietu.txt","template":"release/other/ml_image_list_template.txt"}</div>
          <el-button
              type="primary"
              icon="el-icon-refresh"
              @click="refreshMLImageListCDNFile"
              class="action-btn"
          >立即刷新猛脸P图所有图片列表CDN数据
          </el-button>
        </el-card>
      </el-col>
    </el-row>
  </div>
</template>
<script>

const baseUrl = process.env.VUE_APP_IMAGE_HOST;
export default {
  name: "fileManager",
  data: function () {
    console.log("rotuer.base =" + baseUrl)
    return {
      showLoading: false,//是否显示刷新界面
      cdnFilePath: "",
      deleteFilePath: "",
      uploadFilePath: "",
      filePath: "",
      currentFile: null,
      uploadSupportPath: false,
    }
  },
  methods: {
    // 公共请求处理
    async handleImageListRefreshRequest(url, successMsg) {
      this.showLoading = true;
      try {
        // 只关注请求是否成功，不处理响应内容
        await this.$request.post(url);

        // 成功时直接使用传入的成功提示
        this.showMessage('success', successMsg);

        // 如果需要执行后续操作，可以在这里添加
        // await this.loadImageData();
      } catch (error) {
        // 统一错误处理
        let errorMessage = '操作失败，请稍后重试';

        // 处理不同错误类型
        if (error.response) {
          // 服务器响应异常（4xx/5xx）
          errorMessage = error.response.data?.message
              || `服务器错误: ${error.response.status}`
              || errorMessage;
        } else if (error.request) {
          // 请求已发送但无响应
          errorMessage = '网络连接异常，请检查网络';
        } else {
          // 其他客户端错误
          errorMessage = error.message || errorMessage;
        }

        this.showMessage('error', errorMessage);

        // 可选：上报错误日志
        // this.logError(error);
      } finally {
        this.showLoading = false;
      }
    },
    // 公共请求处理
    async handleRequest(url, data, successMsg) {
      this.showLoading = true;
      try {
        const formData = new FormData();
        Object.entries(data).forEach(([key, value]) => {
          formData.append(key, value);
        });

        const response = await this.$request.post(url, formData);
        this.showMessage(
            response.status === 200 ? 'success' : 'error',
            response.message || successMsg
        );

        // 操作成功后清空相关状态
        if (response.status === 200) {
          this.filePath = "";
          this.currentFile = null;
        }
      } catch (error) {
        this.showMessage('error', error.message || '请求失败');
      } finally {
        this.showLoading = false;
      }
    },
    // 暴走P图图片列表CDN刷新
    async refreshImageListCDNFile() {
      await this.handleImageListRefreshRequest(
          "/test/refreshImageListCdn",
          "暴走P图图片列表CDN刷新成功"
      );
    },
    //猛脸P图图片列表CDN刷新
    async refreshMLImageListCDNFile() {
      await this.handleImageListRefreshRequest(
          "/test/refreshMlImageListCdn",
          "猛脸P图图片列表CDN刷新成功"
      );
    },
    // CDN刷新
    async refreshCDNFile() {
      this.filePath = this.cdnFilePath
      if (!this.validatePath()) return;
      await this.handleRequest(
          "/file/refreshCdn",
          {url: this.filePath},
          "CDN刷新成功"
      );
    },
    // 文件删除
    async deleteFile() {
      this.filePath = this.deleteFilePath
      if (!this.validatePath()) return;
      await this.handleRequest(
          "/file/delete",
          {filePath: this.filePath},
          "文件删除成功"
      );
    },
    // 文件上传
    async uploadFile() {
      this.filePath = this.uploadFilePath
      if (!this.currentFile) {
        this.showMessage('warning', '请先选择要上传的文件');
        return;
      }

      if (this.uploadSupportPath && !this.filePath) {
        this.showMessage('warning', '请填写自定义路径');
        return;
      }

      const formData = {
        file: this.currentFile
      };

      if (this.uploadSupportPath) {
        formData.absoluteFilePath = this.filePath;
      }

      await this.handleRequest(
          "/file/upload",
          formData,
          "文件上传成功"
      );
    },

    // 路径验证
    validatePath() {
      if (!this.filePath.trim()) {
        this.showMessage('warning', '请输入有效的文件路径');
        return false;
      }
      return true;
    },

    // 文件处理
    handleFileChange(file, fileList) {
      this.currentFile = file.raw;
      this.fileList = fileList.slice(-1);
    },

    handleFileRemove() {
      this.currentFile = null;
      this.fileList = [];
    },

    // 消息提示统一处理
    showMessage(type, message) {
      this.$message({
        type,
        message,
        showClose: true,
        duration: 3000
      });
    },

    // 删除确认
    delFile() {
      this.$confirm('此操作将永久删除该文件, 是否继续?', '警告', {
        confirmButtonText: '确认删除',
        cancelButtonText: '取消操作',
        type: 'warning',
        confirmButtonClass: 'el-button--danger',
        center: true
      }).then(this.deleteFile);
    },
  },
  mounted: function () {
    this.$bus.$emit("setMenuActiveIndex", "4-1");
  },
}
</script>

<style scoped>

.container {
  padding: 10px;
  background: #f5f7fa;
  min-height: calc(100vh - 40px);
}

.top-row {
  margin-bottom: 20px;

  .function-card {
    margin: 0 10px;
    transition: transform 0.3s ease;

    &:hover {
      transform: translateY(-3px);
      box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    }
  }
}

.card-header {
  display: flex;
  align-items: center;
  font-size: 16px;
  font-weight: 600;

  i {
    margin-right: 8px;
    font-size: 18px;
  }
}

.card-body {
  padding: 10px;

  .path-input {
    margin-bottom: 10px;

    ::v-deep .el-input-group__prepend {
      background: #f5f7fa;
    }
  }

  .action-btn {
    width: 100%;
    padding: 12px 20px;
    font-size: 14px;
  }
}

.upload-card {
  margin: 0 10px;

  .card-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

.upload-settings {
  display: flex;
  align-items: center;

  .el-switch {
    margin-left: auto;
  }
}

.upload-body {
  padding: 20px;

  .custom-path {
    margin-bottom: 20px;

    .path-input {
      margin-bottom: 15px;
    }
  }
}

.uploader {
  margin-bottom: 20px;

  &.with-path {
    margin-top: 15px;
  }

  ::v-deep .el-upload-dragger {
    padding: 40px 20px;
    border-radius: 8px;
    background: #fbfdff;
    border: 2px dashed #c0ccda;
    transition: all 0.3s ease;

    &:hover {
      border-color: #409EFF;
      background: #ecf5ff;
    }
  }
}

.upload-content {
  padding: 20px;

  .el-icon-upload {
    font-size: 48px;
    color: #c0c4cc;
    margin-bottom: 15px;
  }

  .upload-text {
    .main-text {
      font-size: 14px;
      color: #606266;
      margin-bottom: 4px;
    }

    .sub-text {
      font-size: 12px;
      color: #909399;
    }
  }
}

.upload-btn {
  width: 100%;
  padding: 12px 20px;
  font-size: 14px;

  &:disabled {
    opacity: 0.6;
    cursor: not-allowed;
  }
}
</style>
