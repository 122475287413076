<template>
  <!--    贴图模板，素材的添加修改-->
  <div class="imageBox" v-loading="showLoading">
    <el-form ref="form" :model="images" label-width="80px">
      <div class="inputBox">
        <el-form-item label="预览图片">
          <el-upload
              class="upload-demo"
              action="none"
              :limit="1"
              :file-list="images.fileList"
              :on-exceed="imageCountOut"
              :on-remove="handleRemove"
              :on-change="fileChange"
              :auto-upload="false"
              list-type="picture">
            <el-button size="small" type="primary">点击选择</el-button>
            <div slot="tip" class="el-upload__tip">只能上传一张图片文件，且不超过50MB</div>
          </el-upload>
        </el-form-item>

        <el-form-item label="是否加锁">
          <el-switch
              v-model="images.isLock"
              :active-value="1"
              :inactive-value="0"
              active-color="#67c23a"
              inactive-color="#dcdfe6">
          </el-switch>
        </el-form-item>

        <el-form-item label="图片标签">
          <el-input v-model="images.tag" class="input"></el-input>&nbsp;&nbsp;
          图片热度&nbsp;&nbsp;<el-input v-model="images.heat" class="input" type="number"></el-input>
        </el-form-item>



<!--        <el-form-item label="图片分类">-->
<!--          <el-checkbox-group-->
<!--              style="margin-bottom:10px" -->
<!--          >-->
<!--            <el-checkbox label="A">贴图</el-checkbox>-->
<!--            <el-checkbox label="B">模板</el-checkbox>-->
<!--          </el-checkbox-group>-->
<!--        </el-form-item>-->

        <el-form-item label="素材类型">
          <el-radio-group v-model="images.categoryId" @change="handleCategoryChange">
            <el-radio-button :label="5">贴图</el-radio-button>
            <el-radio-button :label="4">模板</el-radio-button>
          </el-radio-group>
        </el-form-item>

<!--        <el-form-item label="图片分类-旧方法">
          <el-cascader
              @change="selectChange"
              v-model="images.categoryIds"
              :options="categoryList"
              :props="{ value:'id',label:'categoryName',children:'childList'}">
          </el-cascader>
        </el-form-item>-->


        <el-form-item label="模板类型" v-if="hasShowOperation()">
          <el-select v-model="images.templateType" placeholder="请选择模板类型">
            <el-option
                v-for="item in templateType"
                :key="item.value"
                :label="item.label"
                :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <div v-if="hasShowOperation()">
          <el-form-item label="操作数据" v-if="hasShowOperation()">
            <el-upload
                class="upload-demo"
                action="none"
                :limit="1"
                :file-list="images.materialList"
                :on-exceed="imageCountOut"
                :on-remove="materialHandleRemove"
                :on-change="materialFileChange"
                :auto-upload="false"
                list-type="picture">
              <el-button size="small" type="primary">点击选择</el-button>
              <div slot="tip" class="el-upload__tip">只能上传一个文件，且不超过50MB</div>
            </el-upload>
          </el-form-item>
          <el-form-item label="操作数据">
            <el-button type="primary" size="mini" @click="addFontData">添加文字数据</el-button>
            <el-button type="success" size="mini" @click="addImageData">添加图片数据</el-button>
          </el-form-item>
          <div class="operationBox">
            <el-table
                :data="templateDataList"
                style="width: 100%">
              <el-table-column
                  width="80"
                  label="序号">
                <template slot-scope="scope">
                  <p>{{ scope.$index + 1 }}</p>
                </template>
              </el-table-column>
              <el-table-column
                  width="80"
                  label="类型">
                <template slot-scope="scope">
                  <p>{{ scope.row.type === 1 ? "图片" : "文字" }}</p>
                </template>
              </el-table-column>
              <el-table-column
                  prop="title"
                  label="标题"
                  width="150">
              </el-table-column>
              <el-table-column
                  prop="tips"
                  label="提示"
                  width="150">
              </el-table-column>
              <el-table-column
                  prop="left"
                  label="左边距"
                  width="50">
              </el-table-column>
              <el-table-column
                  prop="top"
                  label="上边距"
                  width="50">
              </el-table-column>
              <el-table-column
                  width="50"
                  prop="width"
                  label="宽度">
              </el-table-column>
              <el-table-column
                  width="50"
                  prop="height"
                  label="高度">
              </el-table-column>
              <el-table-column
                  width="50"
                  prop="rotateAngle"
                  label="旋转角度">
              </el-table-column>
              <el-table-column
                  width="80"
                  label="中间操作">
                <template slot-scope="scope">
                  <p>{{ getOperations(scope.row.middleOperation) }}</p>
                </template>
              </el-table-column>
              <el-table-column
                  width="80"
                  label="文字方向">
                <template slot-scope="scope">
                  <p>{{ getFontLocation(scope.row.fontDirection) }}</p>
                </template>
              </el-table-column>
              <el-table-column
                  width="50"
                  prop="fontSize"
                  label="文字大小">
              </el-table-column>
              <el-table-column
                  width="50"
                  prop="fontSum"
                  label="文字数量">
              </el-table-column>
              <el-table-column
                  width="150"
                  label="字体">
                <template slot-scope="scope">
                  <p>{{ scope.row.font !== null ? scope.row.font.name : "" }}</p>
                </template>
              </el-table-column>
              <el-table-column
                  width="50"
                  prop="startTime"
                  label="开始时间(秒)">
              </el-table-column>
              <el-table-column
                  width="50"
                  prop="duration"
                  label="持续时间(秒)">
              </el-table-column>
              <el-table-column
                  fixed="right"
                  width="180"
                  align="center"
                  label="操作">
                <template slot-scope="scope">
                  <el-button type="primary" size="medium" icon="el-icon-edit"
                             @click="updateTemplateData(scope.row)"></el-button>
                  <el-button type="danger" size="medium" icon="el-icon-delete"
                             @click="delTemplateData(scope.row.id)"></el-button>
                </template>
              </el-table-column>
            </el-table>
          </div>
        </div>
      </div>
      <el-form-item class="btn">
        <el-button type="primary" @click="save">保存</el-button>
        <el-button @click="cancel">取消</el-button>
      </el-form-item>
    </el-form>
    <el-dialog :title="dialogTitle"
               @close="closeDialog"
               top="5vh"
               @opened="dialogOpen"
               :visible.sync="showDialog"
               :close-on-click-modal="false"
               width="800px"
               append-to-body
               :destroy-on-close="true"
               :fullscreen="false">
      <TemplateDataAdd v-on:saveStatus="saveStatus"
                       :templateData="templateData"
                       :id="images.id"
                       :is-title="isTitle"
                       :type="images.templateType"
                       :is-load-data="isLoadDialog"
                       :is-add-image-data="isAddImageData"
                       :is-update="isUpdateTemplateData"/>
    </el-dialog>
  </div>
</template>

<script>
import TemplateDataAdd from "./TemplateDataAdd";

export default {
  name: "ImageEdit",
  props: ['images', "categoryList", "isUpdate", "loadData"],
  components: {
    TemplateDataAdd
  },
  watch: {
    loadData: function (flag) {
      if (flag) {
        this.getDataInfo()
      }
    }
  },
  data() {
    return {
      dialogTitle: "",
      showDialog: false,
      showLoading: false,
      postCount: 0,
      templateData: {},
      templateDataList: [],
      secondCategoryId: 2,
      isTitle: false,
      isUpdateTemplateData: false,
      isAddImageData: false,
      isLoadDialog: false,
      templateType: [{
        label: "图片",
        value: 0
      }, {
        label: "视频",
        value: 1
      }]
    }
  },
  methods: {
    getFontLocation: function (location) {
      switch (location) {
        case "left":
          return "左边";
        case "right":
          return "右边";
        case "center":
          return "中间";
      }
      return "";
    },
    getOperations: function (operations) {
      switch (operations) {
        case "head":
          return "换头";
        case "face":
          return "抠脸";
      }
      return "无";
    },
    //是否显示操作选项
    hasShowOperation: function () {
      if (this.isUpdate) {
        if (this.images.secondCategory != null && this.images.secondCategory.id === 2 && this.secondCategoryId === 0) {
          return true;
        } else {
          return this.secondCategoryId === 2
        }
      } else {
        return false;
      }
    },
    imageCountOut: function () {
      this.showErrorMessage("只能选择一个文件")
    },
    /**
     * 图片移除回调
     * @param file
     * @param fileList
     */
    handleRemove: function (file, fileList) {
      this.images.file = null
    },
    /**
     * 图片移除回调
     * @param file
     * @param fileList
     */
    materialHandleRemove: function (file, fileList) {
      this.images.materialFile = null
    },
    cancel: function () {
      this.$emit('saveStatus', false)
    },
    /**
     * 保存上传
     * */
    save() {
      if (!this.isUpdate) {
        this.addImage()
      } else {
        this.update()
      }
    },
    /**
     * 图片选择回调
     * @param file
     * @param fileList
     */
    fileChange: function (file, fileList) {
      this.images.file = file.raw
      //如果是更新，更新图片时，不替换标签
      if (!this.isUpdate)
        this.$set(this.images, "tag", file.raw.name.substring(0, file.raw.name.indexOf('.')))
    },
    /**
     * 图片选择回调
     * @param file
     * @param fileList
     */
    materialFileChange: function (file, fileList) {
      this.images.materialFile = file.raw
      let type = file.raw.name.substring(file.raw.name.lastIndexOf('.'))
      if (type === ".mp4") {
        this.$set(this.images, "templateType", 1)
      } else {
        this.$set(this.images, "templateType", 0)
      }

    },

    handleCategoryChange(newVal) {
      // 确保最多选中一个，点击已选中项时取消
      console.log("handleCategoryChange " + newVal + " " + this.images.categoryId)
      // if (newVal.length > 1) {
      //   this.images.categoryId = [newVal.pop()]; // 保留最新选中的
      // } else {
      //   this.images.categoryId = newVal;
      // }
    },

    selectChange: function (data) {
      console.log("selectChange" + data)
      //匹配三级id
      if (data !== undefined) {
        if (data.length === 3) {
          this.images.categoryId = data[2]
        }
        if (data.length >= 2) {
          this.secondCategoryId = data[1];
        }
        console.log("categoryId " + this.images.categoryId)
      }
    },
    /**
     * 显示错误信息弹框
     * @param msg 错误信息弹框
     */
    showErrorMessage: function (msg) {
      this.$confirm(msg, "提示", {
        confirmButtonText: '确定',
        showClose: false,
        showCancelButton: false,
        center: true,
        type: 'error'
      })
    },
    /**
     * 上传图片
     */
    addImage: function () {
      if (this.images.tag === '' || this.images.tag === undefined) {
        this.showErrorMessage("图片名不能为空")
        return
      }
      if (this.images.heat === undefined || this.images.heat === "") {
        this.showErrorMessage("热度不能为空")
        return
      }
      if (this.images.categoryId === null || this.images.categoryId === undefined) {
        this.showErrorMessage("分类必须是三级分类")
        return
      }
      if (this.images.file === null) {
        this.showErrorMessage("图片不能为空")
        return
      }
      this.showLoading = true;
      const that = this;
      const params = new FormData()
      params.append("file", this.images.file)
      params.append("tag", this.images.tag)
      params.append("hot", this.images.heat)
      params.append("categoryId", this.images.categoryId)
      params.append("isLock", this.images.isLock)
      this.$request.post("/images/addImage", params)
          .then(function (data) {
            that.showLoading = false
            if (data.status === 200) {
              that.$confirm("添加成功", "提示", {
                confirmButtonText: '确定',
                showClose: false,
                showCancelButton: false,
                center: true,
                type: 'success'
              }).then(function () {
                that.$emit('saveStatus', true)
              })
            } else {
              that.showErrorMessage(data.message)
            }
          }).catch(function (err) {
        that.showLoading = false
        that.$confirm("网络错误", "提示", {
          confirmButtonText: '确定',
          showClose: false,
          showCancelButton: false,
          center: true,
          type: 'error'
        })
      })
    },
    update: function () {
      this.showLoading = true;
      const that = this;
      const params = new FormData()
      params.append("id", this.images.id)
      params.append("file", this.images.file)
      params.append("tag", this.images.tag)
      params.append("isLock", this.images.isLock)
      if (this.images.heat === undefined || this.images.heat === "") {
        this.showErrorMessage("热度不能为空")
        return
      }
      params.append("hot", this.images.heat)
      params.append("categoryId", this.images.categoryId)
      params.append("materialImage", this.images.materialFile)
      if (this.images.templateType !== undefined && this.images.templateType !== "") {
        params.append("templateType", this.images.templateType)
      }
      this.$request.post("/images/updateImage", params)
          .then(function (data) {
            that.showLoading = false
            if (data.status === 200) {
              that.$confirm("修改成功", "提示", {
                confirmButtonText: '确定',
                showClose: false,
                showCancelButton: false,
                center: true,
                type: 'success'
              }).then(function () {
                that.$emit('saveStatus', true)
              })
            } else {
              that.showErrorMessage(data.message)
            }
          }).catch(function (err) {
        that.showLoading = false
        that.$confirm("网络错误", "提示", {
          confirmButtonText: '确定',
          showClose: false,
          showCancelButton: false,
          center: true,
          type: 'error'
        })
      })
    },
    //添加文字信息
    addFontData: function () {
      this.dialogTitle = "添加文字信息"
      this.isAddImageData = false
      this.showDialog = true
    },
    //添加图片信息
    addImageData: function () {
      this.dialogTitle = "添加图片信息"
      this.isAddImageData = true
      this.showDialog = true
    },
    closeDialog: function () {
      this.templateData = {}
      this.isUpdateTemplateData = false
      this.isAddImageData = false
      this.isLoadDialog = false
    },
    /**
     * 数据保存状态
     * @param info
     */
    saveStatus: function (info) {
      if (info !== false) {
        this.getDataInfo()
      }
      this.showDialog = false
    },
    getDataInfo: function () {
      if (this.hasShowOperation()) {
        this.showLoading = true
        const params = new URLSearchParams()
        const that = this;
        params.append("id", this.images.id)
        this.$request.post("/autoTemplate/getAutoTemplateDataList", params)
            .then(function (data) {
              that.showLoading = false
              that.templateDataList = data.data
            }).catch(function (err) {
          that.showLoading = false
        })
      }
    },
    updateTemplateData: function (data) {
      this.isUpdateTemplateData = true
      this.templateData = data
      if (data.type === 1) {
        this.dialogTitle = "修改图片信息"
        this.showDialog = true
      } else {
        this.dialogTitle = "修改文本信息"
        this.showDialog = true
      }
    },
    delTemplateData: function (id) {
      this.showLoading = true
      const params = new URLSearchParams()
      const that = this;
      params.append("id", id)
      this.$request.post("/autoTemplate/delAutoTemplateById", params)
          .then(function (data) {
            that.showLoading = false
            if (data.status === 200) {
              that.$confirm("删除成功", "提示", {
                confirmButtonText: '确定',
                showClose: false,
                showCancelButton: false,
                center: true,
                type: 'success'
              }).then(function () {
                that.getDataInfo()
              })
            } else {
              that.showMessage(data.message)
            }
          }).catch(function (err) {
        that.showLoading = false
        that.showMessage("网络错误")
      })
    },
    dialogOpen: function () {
      this.isLoadDialog = true
    }
  },
  mounted() {

    if (this.images.heat === undefined) {
      this.$set(this.images, "heat", 4000)
    }

    if (this.images.categoryIds === undefined) {
      this.$set(this.images, "categoryIds", [1, 3, 5])
      this.$set(this.images, "categoryId", 5)

    }
  }
}
</script>

<style scoped>
.imageBox {
  width: 100%;
  min-height: 300px;
}

.inputBox {
  max-height: 400px;
  overflow-y: scroll;
  margin-bottom: 20px;
  padding-bottom: 20px;
}

.inputBox::-webkit-scrollbar {
  display: none;
}

.btn /deep/ .el-form-item__content {
  margin-left: 0 !important;
  text-align: center;
}

.input {
  width: 300px;
}

.operationBox {
  width: 100%;
}
</style>
