<template>
  <div v-loading.fullscreen.lock="showLoading">
    <div class="top_box">
      <el-tabs type="card" v-model="activeTab" @tab-click="handleTabClick">
        <!-- 会员情况查询 -->
        <el-tab-pane label="会员情况查询" name="0">
          <div class="box_operation">
            <div class="input_box">
              <el-input placeholder="请输入用户ID或者订单号ID进行查询" v-model="searchQuery"
                        class="input-with-select"></el-input>
            </div>
            <div class="btn_box">
              <el-button type="success" @click="searchUserInfo">查询</el-button>
            </div>
          </div>

          <el-card>
            <div slot="header" class="clearfix">
              <span>基本信息（user_info表）</span>
            </div>
            <el-table :data="vipPayList.userList" :row-class-name="tableRowClassName"
                      style="width: 100%" max-height="250"
                      :default-sort="{ prop: 'id', order: 'descending' }">
              <el-table-column prop="id" label="id" width="120" sortable></el-table-column>
              <el-table-column prop="openId" label="用户ID" width="250"></el-table-column>
              <el-table-column prop="userName" label="用户名" width="150"></el-table-column>
              <el-table-column prop="vipExpireTime" label="会员到期时间" width="160"></el-table-column>
              <el-table-column prop="unionId" label="唯一ID" width="250"></el-table-column>
              <el-table-column prop="deviceId" label="设备ID" width="250"></el-table-column>
              <el-table-column prop="phoneNumber" label="手机号" width="100"></el-table-column>
              <el-table-column prop="time" label="创建时间" width="160"></el-table-column>
              <el-table-column prop="updateTime" label="更新时间" width="160"></el-table-column>
            </el-table>
          </el-card>
          <div class="tutorial_box"/>
          <el-card>
            <div slot="header" class="clearfix">
              <span>支付记录（pay_log表）</span>
            </div>
            <el-table :data="vipPayList.payInfoList"
                      :row-class-name="tableRowClassName" style="width: 100%" max-height="500"
                      :default-sort="{ prop: 'id', order: 'descending' }">
              <el-table-column prop="id" label="id" width="120" sortable></el-table-column>
              <el-table-column prop="openId" label="用户ID" width="250"></el-table-column>
              <el-table-column prop="outTradeNo" label="内部订单号" width="200"></el-table-column>
              <el-table-column prop="tradeNo" label="订单号"></el-table-column>
              <el-table-column prop="resultStatus" label="支付状态" width="120"></el-table-column>
              <el-table-column prop="payWay" label="支付方式" width="120">
                <template slot-scope="scope">
                  <p>{{
                      scope.row.payWay === "1" ? "支付宝" : scope.row.payWay === "2" ? "微信" : scope.row.payWay === "3" ? "苹果" : "其他"
                    }}</p>
                </template>
              </el-table-column>
              <el-table-column prop="totalFee" label="费用" width="120">
                <template slot-scope="scope">
                  <p>{{
                      scope.row.totalFee + "元"
                    }}</p>
                </template>
              </el-table-column>
              <el-table-column prop="time" label="vip时长" width="120">
                <template slot-scope="scope">
                  <p>{{
                      scope.row.time + "天"
                    }}</p>
                </template>
              </el-table-column>
              <el-table-column prop="createTime" label="创建时间" width="160"></el-table-column>
              <el-table-column prop="updateTime" label="更新时间" width="160"></el-table-column>
            </el-table>
          </el-card>
        </el-tab-pane>

        <!-- 会员同步 -->
        <el-tab-pane label="会员同步" name="1">
          <div class="tutorial_box">
            <div class="input_box">
              <el-input placeholder="请输入用户ID" v-model="newUserId" class="input-with-select"></el-input>
            </div>
            <div class="input_box">
              <el-input placeholder="请输入需要同步的用户ID" v-model="userId" class="input-with-select"></el-input>
            </div>
            <div class="btn_box">
              <el-button type="success" @click="syncUser">会员同步</el-button>
            </div>
          </div>
          <el-card v-if="syncResult">
            <div slot="header" class="clearfix">
              <span>同步结果</span>
            </div>
            <div>
              <el-tag type="danger">{{ syncResult }}</el-tag>
            </div>
          </el-card>
          <el-card v-if="syncedUserInfo">
            <div slot="header" class="clearfix">
              <span>同步后的会员信息</span>
            </div>
            <el-table :data="syncedUserInfo" style="width: 100%" max-height="250"
                      :default-sort="{ prop: 'id', order: 'descending' }">
              <el-table-column prop="id" label="id" width="80" sortable></el-table-column>
              <el-table-column prop="openId" label="用户ID" width="250"></el-table-column>
              <el-table-column prop="userName" label="用户名" width="150"></el-table-column>
              <el-table-column prop="vipExpireTime" label="会员到期时间" width="160"></el-table-column>
              <el-table-column prop="unionId" label="唯一ID" width="250"></el-table-column>
              <el-table-column prop="phoneNumber" label="手机号" width="100"></el-table-column>
              <el-table-column prop="time" label="创建时间" width="160"></el-table-column>
              <el-table-column prop="updateTime" label="更新时间" width="160"></el-table-column>
            </el-table>
          </el-card>

        </el-tab-pane>

        <!-- 会员修改 -->
        <el-tab-pane label="会员修改" name="2">
          <div class="tutorial_box">
            <div class="input_box">
              <el-input placeholder="请输入用户ID" v-model="modifyUserId" class="input-with-select"></el-input>
            </div>
            <div class="select_box">
              <el-select v-model="selectedDuration" placeholder="请选择会员到期时间">
                <el-option label="取消会员" value="0"></el-option>
                <el-option label="7天" value="7"></el-option>
                <el-option label="1个月" value="30"></el-option>
                <el-option label="3个月" value="90"></el-option>
                <el-option label="半年" value="180"></el-option>
                <el-option label="1年" value="365"></el-option>
                <el-option label="永久" value="permanent"></el-option>
              </el-select>
            </div>
            <div class="btn_box">
              <el-button type="primary" @click="searchUserInfoInUpdate">查询用户信息</el-button>
            </div>
            <div class="btn_box">
              <el-button type="success" @click="modifyUser">提交修改</el-button>
            </div>
          </div>
          <el-card v-if="vipPayList.userList.length>0">
            <div slot="header" class="clearfix">
              <span>基本信息（user_info表）</span>
            </div>
            <el-table :data="vipPayList.userList" :row-class-name="tableRowClassName"
                      style="width: 100%" max-height="250"
                      :default-sort="{ prop: 'id', order: 'descending' }">
              <el-table-column prop="id" label="id" width="120" sortable></el-table-column>
              <el-table-column prop="openId" label="用户ID" width="250"></el-table-column>
              <el-table-column prop="userName" label="用户名" width="150"></el-table-column>
              <el-table-column prop="vipExpireTime" label="会员到期时间" width="160"></el-table-column>
              <el-table-column prop="unionId" label="唯一ID" width="250"></el-table-column>
              <el-table-column prop="deviceId" label="设备ID" width="250"></el-table-column>
              <el-table-column prop="phoneNumber" label="手机号" width="100"></el-table-column>
              <el-table-column prop="time" label="创建时间" width="160"></el-table-column>
              <el-table-column prop="updateTime" label="更新时间" width="160"></el-table-column>
            </el-table>
          </el-card>
          <div class="tutorial_box" v-if="vipPayList.payInfoList.length>0"/>
          <el-card v-if="vipPayList.payInfoList.length>0">
            <div slot="header" class="clearfix">
              <span>支付记录（pay_log表）</span>
            </div>
            <el-table :data="vipPayList.payInfoList"
                      :row-class-name="tableRowClassName" style="width: 100%" max-height="500"
                      :default-sort="{ prop: 'id', order: 'descending' }">
              <el-table-column prop="id" label="id" width="120" sortable></el-table-column>
              <el-table-column prop="openId" label="用户ID" width="250"></el-table-column>
              <el-table-column prop="outTradeNo" label="内部订单号" width="200"></el-table-column>
              <el-table-column prop="tradeNo" label="订单号"></el-table-column>
              <el-table-column prop="resultStatus" label="支付状态" width="120"></el-table-column>
              <el-table-column prop="payWay" label="支付方式" width="120">
                <template slot-scope="scope">
                  <p>{{
                      scope.row.payWay === "1" ? "支付宝" : scope.row.payWay === "2" ? "微信" : scope.row.payWay === "3" ? "苹果" : "其他"
                    }}</p>
                </template>
              </el-table-column>
              <el-table-column prop="totalFee" label="费用" width="120">
                <template slot-scope="scope">
                  <p>{{
                      scope.row.totalFee + "元"
                    }}</p>
                </template>
              </el-table-column>
              <el-table-column prop="time" label="vip时长" width="120">
                <template slot-scope="scope">
                  <p>{{
                      scope.row.time + "天"
                    }}</p>
                </template>
              </el-table-column>
              <el-table-column prop="createTime" label="创建时间" width="160"></el-table-column>
              <el-table-column prop="updateTime" label="更新时间" width="160"></el-table-column>
            </el-table>
          </el-card>
          <div class="tutorial_box"/>
          <el-card v-if="modifyResult">
            <div slot="header" class="clearfix">
              <span>修改结果</span>
            </div>
            <div>{{ modifyResult }}</div>
          </el-card>
          <div class="tutorial_box"/>
          <el-card v-if="modifiedUserList">
            <div slot="header" class="clearfix">
              <span>修改后的会员信息</span>
            </div>
            <el-table :data="modifiedUserList" style="width: 100%" max-height="250"
                      :default-sort="{ prop: 'id', order: 'descending' }">
              <el-table-column prop="id" label="id" width="80"></el-table-column>
              <el-table-column prop="openId" label="用户ID" width="250"></el-table-column>
              <el-table-column prop="userName" label="用户名" width="150"></el-table-column>
              <el-table-column prop="vipExpireTime" label="会员到期时间" width="160"></el-table-column>
              <el-table-column prop="unionId" label="唯一ID" width="250"></el-table-column>
              <el-table-column prop="phoneNumber" label="手机号" width="100"></el-table-column>
              <el-table-column prop="time" label="创建时间" width="160"></el-table-column>
              <el-table-column prop="updateTime" label="更新时间" width="160"></el-table-column>
            </el-table>
          </el-card>
        </el-tab-pane>

        <el-tab-pane label="会员退款" name="3">
          <div class="tutorial_box">
            <div class="input_box">
              <el-input placeholder="请输入订单号" v-model="refundInfo.tradeNo" class="input-with-select"></el-input>
            </div>
            <div class="select_box">
              <el-select v-model="refundInfo.payWay" placeholder="请选择会员支付方式">
                <el-option
                    v-for="item in payWays"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value">
                </el-option>
              </el-select>
            </div>
            <div class="input_box">
              <el-input placeholder="请输入退款金额" @input="handleInput"
                        v-model="refundInfo.price" class="input-with-select"></el-input>
            </div>
            <div class="btn_box">
              <el-button type="primary" @click="queryTrade">查询交易记录</el-button>
              <el-button type="danger" @click="refundTrade">微信/支付宝退款</el-button>
              <el-button type="success" @click="queryUserAfterRefund">查询用户信息</el-button>
            </div>
          </div>
          <el-card>
            <div slot="header" class="clearfix">
              <div class="payment-status">
                <span>结果</span>
                <el-tag :type="success" v-if="getPaymentStatusText(refundResult)">
                  {{ getPaymentStatusText(refundResult) }}
                </el-tag>
              </div>
            </div>
            <pre v-if="refundResult" class="json-display">{{ JSON.stringify(refundResult, null, 2) }}</pre>
          </el-card>
        </el-tab-pane>
        <!-- 操作记录 -->
        <el-tab-pane label="操作记录" name="4">
          <el-card>
            <div slot="header" class="clearfix">
              <span>操作记录</span>
            </div>
            <el-table :data="operationLogs" style="width: 100%" max-height="500"
                      :default-sort="{ prop: 'id', order: 'descending' }">
              <el-table-column prop="id" label="记录ID" sortable width="80"></el-table-column>
              <el-table-column prop="type" label="操作类型" width="120"></el-table-column>
              <el-table-column prop="openId" label="用户ID" width="250"></el-table-column>
              <el-table-column prop="reason" label="操作详情"></el-table-column>
              <el-table-column prop="relatedId" label="同步的用户ID"></el-table-column>
              <el-table-column prop="vipExpireTime" label="VIP到期时间" width="160"></el-table-column>
              <el-table-column prop="createTime" label="操作时间" width="160"></el-table-column>
            </el-table>
          </el-card>
        </el-tab-pane>
      </el-tabs>
    </div>
  </div>
</template>

<script>

export default {
  name: "vipReportManager",
  components: {},
  data: function () {
    return {
      activeTab: '0', // 默认选中第一个 Tab
      showLoading: false,
      searchQuery: '',
      vipPayList: {
        userList: [],
        payInfoList: []
      },
      payWays: [{
        label: "支付宝",
        value: 1
      }, {
        label: "微信",
        value: 2
      },
      ],
      syncResult: '', // 同步结果
      syncedUserInfo: null, // 同步后的会员信息
      newUserId: '',//新用户ID
      userId: '', // 老用户ID
      modifyUserId: '', // 用户ID
      selectedDuration: '', // 选择的会员到期时间
      modifyResult: '', // 修改结果
      modifiedUserList: null, // 修改后的会员信息
      refundInfo: {
        payWay: 2, // 用户ID
        tradeNo: '', // 订单号
        price: '' // 价格
      },
      refundResult: '', // 退款结果
      operationLogs: null,
    };
  },
  methods: {
    handleInput(value) {
      // 只允许输入数字和小数点
      const filteredValue = value.replace(/[^0-9.]/g, '');

      // 限制小数点后最多两位
      const parts = filteredValue.split('.');
      if (parts.length > 1) {
        parts[1] = parts[1].slice(0, 2);
      }
      const formattedValue = parts.join('.');

      // 限制范围在0到100之间
      if (formattedValue !== '') {
        const numericValue = parseFloat(formattedValue);
        if (numericValue < 0) {
          this.refundInfo.price = '0';
        } else if (numericValue > 100) {
          this.refundInfo.price = '100';
        } else {
          this.refundInfo.price = formattedValue;
        }
      } else {
        this.refundInfo.price = formattedValue;
      }
    },
    // 获取支付状态文本
    getPaymentStatusText(result) {
      if (!result) return null; // 如果 result 为空，直接返回 null

      if (this.refundInfo.payWay === 2) {
        // 微信支付
        return result['tradeStateDesc'] || null; // 如果没有值，返回 null
      } else if (this.refundInfo.payWay === 1) {
        // 支付宝支付
        return result['tradeStatus'] || null; // 如果没有值，返回 null
      }
      return null; // 未知状态
    },
    handleTabClick(tab) {
      // 保存当前选中的 Tab 索引到 localStorage
      localStorage.setItem('activeTab', tab.name);
      if (tab.name === "4") {
        //获取数据
        this.queryVipAdminLog()
      }
    },
    queryVipAdminLog() {
      // 模拟提交修改
      console.log("直接请求")
      const that = this;
      const params = {}
      this.$request.post("/pay/vip/getAllVipAdminLog", params, {
        // json格式
        headers: {
          "Content-Type": "application/json"
        },
      })
          .then(function (data) {
            that.showLoading = false
            console.log("返回结果", data)
            if (data.status === 200) {
              // 保存结果到本地存储
              that.operationLogs = data.data
            } else {
              that.operationLogs = null
            }
          }).catch(function (err) {
        console.log(err)
        that.showMsg("网络错误")
      })
    },
    modifyUser() {
      // 判断 this.searchQuery 是否为空
      if (!this.modifyUserId) {
        this.showMsg("用户ID不能为空");
        return;
      }
      // 计算会员到期时间戳
      const now = Date.now(); // 当前时间戳
      let expireTime = now;
      switch (this.selectedDuration) {
        case '0': // 取消会员
          expireTime = now;
          break;
        case '7': // 7天
          expireTime = now + 7 * 24 * 60 * 60 * 1000;
          break;
        case '30': // 1个月
          expireTime = now + 30 * 24 * 60 * 60 * 1000;
          break;
        case '90': // 3个月
          expireTime = now + 90 * 24 * 60 * 60 * 1000;
          break;
        case '180': // 半年
          expireTime = now + 183 * 24 * 60 * 60 * 1000;
          break;
        case '365': // 1年
          expireTime = now + 365 * 24 * 60 * 60 * 1000;
          break;
        case 'permanent': // 永久
          expireTime = now + 24855 * 24 * 60 * 60 * 1000;
          break;
        default:
          this.$message.error('请选择会员到期时间');
          return;
      }
      // 模拟提交修改
      this.showLoading = true;

      console.log("直接请求")
      const that = this;
      const params = {
        "userId": this.modifyUserId,
        "vipExpireTime": expireTime,
      }
      this.$request.post("/pay/vip/updateUserVipExpireDate", params, {
        // json格式
        headers: {
          "Content-Type": "application/json"
        },
      })
          .then(function (data) {
            that.showLoading = false
            console.log("返回结果", data)
            if (data.status === 200) {
              // 保存结果到本地存储
              var result = data.data;
              if (result.result) {
                that.modifyResult = `用户ID ${that.modifyUserId} 的会员到期时间已修改为：${
                    expireTime === '永久' ? '永久' : new Date(expireTime).toLocaleString()
                }`;
                that.modifiedUserList = result.userList;
              } else {
                that.modifyResult = "同步修改失败，请检查参数是否正确";
                that.modifiedUserList = null;
              }
            } else {
              that.modifyResult = "同步修改失败，请检查参数是否正确";
            }

          }).catch(function (err) {
        console.log(err)
        that.showLoading = false
        that.showMsg("网络错误")
      })

    },
    tableRowClassName({row, rowIndex}) {
      return '';
    },
    /**
     * 显示错误消息
     * @param title
     */
    showMsg: function (title) {
      this.$confirm(title, "提示", {
        confirmButtonText: '确定',
        showClose: false,
        showCancelButton: false,
        center: true,
        type: 'error'
      })
    },
    changePage: function (pageNo) {
      this.pageNo = pageNo;
      this.getVipStatisticsList()
    },
    //会员修改中，查询用户信息，需要将输入值复制给searchQuery
    searchUserInfoInUpdate: function () {
      // 判断 this.searchQuery 是否为空
      if (!this.modifyUserId) {
        this.showMsg("用户ID不能为空");
        return;
      }
      this.searchQuery = this.modifyUserId;
      this.searchUserInfo();
    },
    searchUserInfo: function () {
      // 判断 this.searchQuery 是否为空
      if (!this.searchQuery) {
        this.showMsg("搜索信息不能为空");
        return;
      }
      this.showLoading = true;
      console.log("直接请求")
      const that = this;
      const params = {
        "searchInfo": this.searchQuery,
      }
      this.$request.post("/pay/vip/getUserPayInfo", params, {
        // json格式
        headers: {
          "Content-Type": "application/json"
        },
      })
          .then(function (data) {
            that.showLoading = false
            console.log("返回结果", data)
            if (data.status === 200) {
              // 保存结果到本地存储
              const result = data.data;
              that.vipPayList = result;
            } else {
              that.showMsg(data.data)
            }
          }).catch(function (err) {
        console.log(err)
        that.showLoading = false
        that.showMsg("网络错误")
      })
    },
    syncUser: function () {
      console.log("直接请求")
      if (!this.userId || !this.newUserId) {
        this.showMsg("搜索信息不能为空");
        return;
      }
      this.showLoading = true;
      const that = this;
      const params = {
        "userId": this.newUserId,
        "syncVipUserId": this.userId,
      }
      this.$request.post("/pay/vip/syncVipInfo", params, {
        // json格式
        headers: {
          "Content-Type": "application/json"
        },
      })
          .then(function (data) {
            that.showLoading = false
            console.log("返回结果", data)
            if (data.status === 200) {
              // 保存结果到本地存储
              var result = data.data;
              if (result.result) {
                that.syncResult = "同步会员成功";
                that.syncedUserInfo = result.userList;
              } else {
                that.syncResult = "同步会员失败，请检查参数是否正确";
                that.syncedUserInfo = null;
              }
            } else {
              that.syncResult = "同步会员失败，请检查参数是否正确";
            }
          }).catch(function (err) {
        console.log(err)
        that.showLoading = false
        that.showMsg("网络错误")
      })
    },
    queryTrade: function () {
      console.log("查询退款记录 ", this.refundInfo);
      if (!this.refundInfo.tradeNo) {
        this.showMsg("订单号不能为空");
        return;
      }
      this.showLoading = true;
      const that = this;
      const params = {
        "tradeNo": this.refundInfo.tradeNo,
        "payWay": this.refundInfo.payWay,
      }
      console.log("查询退款记录", params);
      this.$request.post("/pay/queryTransactions", params, {
        // json格式
        headers: {
          "Content-Type": "application/json"
        },
      })
          .then(function (data) {
            that.showLoading = false
            console.log("返回结果", data)
            if (data.status === 200) {
              // 保存结果到本地存储
              var result = data.data;
              that.refundResult = result
            } else {
              that.refundResult = "查询失败，请检查参数是否正确";
            }
          }).catch(function (err) {
        console.log(err)
        that.showLoading = false
        that.showMsg("网络错误")
      })
    },
    //退款中查询用户信息
    queryUserAfterRefund: function () {
      console.log("退款中查询用户信息")
      if (!this.refundInfo.tradeNo) {
        this.showMsg("订单号不能为空");
        return;
      }
      this.activeTab = "0"
      this.searchQuery = this.refundInfo.tradeNo;
      this.searchUserInfo()
    },
    refundTrade: function () {
      console.log("退款")
      if (!this.refundInfo.tradeNo) {
        this.showMsg("订单号不能为空");
        return;
      }
      if (!this.refundInfo.price || this.refundInfo.price === 0) {
        this.showMsg("退款金额不能为空");
        return;
      }
      this.showLoading = true;
      const that = this;
      const params = {
        "tradeNo": this.refundInfo.tradeNo,
        "payWay": this.refundInfo.payWay,
        "price": this.refundInfo.price,
      }
      this.$request.post("/pay/appPayRefund", params, {
        // json格式
        headers: {
          "Content-Type": "application/json"
        },
      })
          .then(function (data) {
            that.showLoading = false
            console.log("返回结果", data)
            if (data.status === 200) {
              // 保存结果到本地存储
              const result = data.data;
              that.refundResult = result
            } else {
              that.refundResult = "退款失败，请检查参数是否正确";
            }
          }).catch(function (err) {
        console.log(err)
        that.showLoading = false
        that.showMsg("网络错误")
      })
    },
    reset: function () {
      this.pageNo = 1;
      this.searchName = "";
      this.searchKey = "";
      this.showMore = -1;
      this.orderType = 2;
      this.getVipStatisticsList()
    },
    closeDialog: function () {
      this.selectMessage = {}
    },
  },
  mounted() {
    this.$bus.$emit("setMenuActiveIndex", "9-2"); // 从 localStorage 中读取保存的 Tab 索引
    const activeTab = localStorage.getItem('activeTab') || '0';
    this.activeTab = activeTab;
  }
}
</script>

<style scoped>
.top_box {
  width: 100%;
  background: white;
  min-height: 100%;
  border-radius: 5px;
  padding: 10px 20px;
}

.tutorial_box {
  width: 100%;
  margin-bottom: 16px;
  background: white;
  min-height: 100%;
  border-radius: 5px;
  padding: 4px 10px;
}

.box_operation {
  width: 100%;
  padding: 20px 0;
}

.select_box {
  display: inline-block;
  margin: 0 10px;
  margin-top: 10px;
}

.input_box {
  display: inline-block;
  width: 300px;
  margin: 0 10px;
  margin-top: 10px;
}

.btn_box {
  margin-top: 10px;
  height: 40px;
  margin-left: 20px;
  vertical-align: top;
  display: inline-block;
}

.el-table .warning-row {
  background: oldlace;
}

.el-table .success-row {
  background: #f0f9eb;
}

.data_box /deep/ .el-table .warning-row {
  background: oldlace;
}

.data_box /deep/ .el-table .success-row {
  background: #f0f9eb;
}

.clearfix:before,
.clearfix:after {
  display: table;
  content: "";
}

.clearfix:after {
  clear: both
}

.json-display {
  max-height: 300px; /* 设置最大高度 */
  overflow-y: auto; /* 启用垂直滚动条 */
  background-color: #f5f5f5; /* 背景色 */
  padding: 10px; /* 内边距 */
  border-radius: 4px; /* 圆角 */
  white-space: pre-wrap; /* 保留换行和空格 */
  word-wrap: break-word; /* 长单词换行 */
}
</style>
